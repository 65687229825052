<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ `${user.firstname} ${user.lastname}` }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_USERS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editProfile()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>
              </h3>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <user-view-global :user="user" />
                </tab-pane>
                <tab-pane title="global" id="2" v-if="user?.organization">
                  <span slot="title">
                    <i class="ni ni-building" />
                    {{ $t("COMMON.ORGANIZATION") }}
                  </span>
                  <organization-view-global
                    :showEditButton="true"
                    @openOrganizationEditFormModal="openOrganizationEditForm"
                    :organization="user?.organization"
                  />
                </tab-pane>

                <tab-pane
                  title="authorizations"
                  id="3"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
                >
                  <span slot="title">
                    <i class="ni ni-key-25" />
                    {{ $t("USERS.AUTHORIZATIONS") }}
                  </span>
                  <user-view-authorizations :user="user" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <user-view-logs :user="user" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>

    <modal
      :show="
        showModal && $currentUserCan($permissions.PERM_EDIT_HIS_ORGANIZATIONS)
      "
      modal-classes="modal-secondary"
      size="xl"
    >
      <div
        class="card-body"
        v-if="$currentUserCan($permissions.PERM_EDIT_HIS_ORGANIZATIONS)"
      >
        <organization-form
          :loading="loading"
          :organizationData="organization"
          :formErrors="formErrors"
          @organizationSubmitted="handleSubmit"
          @cancelOrganizationForm="closeOrganizationEditForm"
          :showCancelButton="true"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import { TabPane, Tabs } from "@/components";
import UserViewGlobal from "@/views/Pages/AdminModule/UserManagement/partials/UserViewGlobal.vue";
import UserViewAuthorizations from "@/views/Pages/AdminModule/UserManagement/partials/UserViewAuthorizations.vue";
import UserViewLogs from "@/views/Pages/AdminModule/UserManagement/partials/UserViewLogs.vue";
import defaultUser from "@/views/Pages/AdminModule/UserManagement/defaultUser";
import OrganizationViewGlobal from "@/views/Pages/AdminModule/OrganizationManagement/partials/OrganizationViewGlobal";
import OrganizationForm from "@/views/Pages/AdminModule/OrganizationManagement/partials/OrganizationForm";
import alertLeave from "@/mixins/alert-leave-mixin";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import defaultOrganization from "@/views/Pages/AdminModule/OrganizationManagement/defaultOrganization";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    UserViewGlobal,
    UserViewAuthorizations,
    OrganizationViewGlobal,
    UserViewLogs,
    OrganizationForm,
  },

  mixins: [formMixin, requestErrorMixin, alertLeave],

  data() {
    return {
      user: cloneDeep(defaultUser),
      organization: cloneDeep(defaultOrganization),
      showModal: false,
      formErrors: null,
      loading: false,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    openOrganizationEditForm() {
      this.showModal = true;
    },

    closeOrganizationEditForm() {
      this.showModal = false;
    },

    async get() {
      try {
        await this.$store.dispatch("profile/me");
        this.user = this.$store.getters["profile/me"];
        this.organization = {
          ...this.organization,
          ...this.user.organization,
          reseller: {
            id: this.user.organization.reseller_id,
            type: "resellers",
          },
          owner: {
            id: this.user.organization.owner?.id,
            type: "users",
          },
        };
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editProfile() {
      this.$router.push({
        name: "Edit Profile",
      });
    },

    async handleSubmit(organization) {
      this.loading = true;
      const reseller = this.user?.organization?.reseller_id
        ? {
            id: this.user.organization.reseller_id.toString(),
            type: "resellers",
          }
        : undefined;
      const owner = this.user?.organization?.owner_id
        ? {
            id: this.user.organization.owner_id.toString(),
            type: "users",
          }
        : undefined;

      const organizationData = cloneDeep({
        ...organization,
        reseller,
        owner,
      });

      try {
        await this.$store.dispatch("organizations/update", organizationData);
        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.ORGANIZATION_UPDATED"),
        });
        await this.get();
        this.loading = false;
        this.closeOrganizationEditForm();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        if (error?.response?.status === 403) {
          await this.showRequestError(error);
        } else {
          this.formErrors = error.response.data.errors;
        }
        this.loading = false;
      }
    },
  },
};
</script>
